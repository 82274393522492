// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/utils/userUtils.tsx"
);
import.meta.hot.lastModified = "1708335860448.164";
}
// REMIX HMR END

import { json } from "@remix-run/node";
import { userPrefs } from "~/cookies.server";
import { getLocalStorage, storageAvailable } from "./fetchUtils";
import { FC, createContext, useContext, useEffect, useState } from "react";
import { Deal } from "~/components/post/EditPost";
import { auth } from "./fetchUtils.server";

export const useLocalStorage = () => {
  const [value, setValue] = useState<string | null>(null);

  const setItem = (key: string, value: string) => {
    localStorage.setItem(key, value);
    setValue(value);
  };

  const getItem = (key: string) => {
    const value = localStorage.getItem(key);
    setValue(value);
    return value;
  };

  const removeItem = (key: string) => {
    localStorage.removeItem(key);
    setValue(null);
  };

  return { value, setItem, getItem, removeItem };
};

// NOTE: optimally move this into a separate file
export interface User {
  id: string;
  username: string;
  email: string;
  authToken: string;
  profilePicture?: string;
  profilePictureID?: string;
  profilePictureThumb?: string;
  role?: string;
  credits?: number;
  confirmed: boolean;
  blocked: boolean;
  provider: string;
  bookmarks: any;
  lastLogin?: number;
  userLevel?: number;
  pushs?: any[];
}

export const useUser = () => {
  const { user, setUser } = useContext(AuthContext);
  const { setItem } = useLocalStorage();

  const addUser = (user: User) => {
    setUser(user);
    setItem("user", JSON.stringify(user));
  };

  const removeUser = () => {
    setUser(null);
    setItem("user", "");
  };

  return { user, addUser, removeUser };
};

export function isEditor(user: User | null): boolean {
  return !!(user && user?.role && user.role == "editor");
}


interface AuthContext {
  user: User | null;
  setUser: (user: User | null) => void;
  onOpen: () => void;
}

export const useAuth = () => {
  const { user, addUser, removeUser } = useUser();
  const { getItem } = useLocalStorage();
  const { onOpen } = useContext(AuthContext);

  useEffect(() => {
    const user = getItem("user");
    if (user) {
      addUser(JSON.parse(user));
    }
  }, []);

  const login = (userData: User) => {
    addUser({ ...user, ...userData });
  };

  const logout = () => {
    removeUser();
  };

  const checkUsershowLogin = () => {
    !user && onOpen();
    return !!user
  };

  return { user, userLevel: user?.userLevel || 0, login, logout, checkUsershowLogin };
};

export const AuthContext = createContext<AuthContext>({
  user: null,
  setUser: () => {},
  onOpen: () => {}
});
